<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.system_logs') }}</h1>
        <FiltersBar @reset="setDatesFilters"
                    ref="filtersBar"></FiltersBar>
        <SystemLogTable
            @change-page="changePage($event)"
            @change-amount="changeAmount($event)"
            @sort="sortingColumn = $event"
            @fech="fetchSystemLogs"
            :changed-page="pagination.page"
            :loader="loading"
        ></SystemLogTable>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { FILTERS, FILTERS_SEARCHING } from '@/constants/filtersModelNames';
import { PARSE_DATE, CONVERT_TO_UTC, GET_BEGINNING_OF_CURRENT_MONTH } from '@/helpers/dates';
import FiltersBar from '@/components/widgets/tables/FiltersBar';
import SystemLogTable from '@/components/systemSettings/SystemLogTable';
import filtering from '@/mixins/filtering';
import debouncedEmployees from '@/mixins/debounced/debouncedEmployees';
import DatePickerFilter from '@/models/filters/datePickerFilter';
import AutocompleteFilter from '@/models/filters/autocompleteFilter';
import { mapFields } from 'vuex-map-fields';
import debounce from 'lodash.debounce';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'SystemLog',
    mixins: [filtering, debouncedEmployees, paginationSettings],
    components: { FiltersBar, SystemLogTable },
    data: (vm) => ({
        pagination: vm.setupPagination(),
        sortingColumn: null,
        loading: false
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ]),
        ...mapState('locale', [
            'currentLocale'
        ]),
        ...mapState('systemSettings', [
            'systemLogsAmount'
        ]),
        ...mapState('systemLogTypes', [
            'systemLogTypes'
        ]),
        filtersConfig () {
            return [
                new AutocompleteFilter({
                    model: FILTERS.SYSTEM_LOG_TYPE_ID,
                    label: this.$t('labels.system_log_types'),
                    multiple: true,
                    loading: true,
                    items: {
                        obj: 'getters',
                        module: 'systemLogTypes',
                        prop: 'filterableTypes'
                    }
                }),
                new DatePickerFilter({
                    model: FILTERS.OCC_START_DATE,
                    label: this.$t('labels.date_from')
                }),
                new DatePickerFilter({
                    model: FILTERS.OCC_END_DATE,
                    label: this.$t('labels.date_to')
                }),
                new AutocompleteFilter({
                    model: FILTERS.PERSON_ID,
                    label: this.$t('labels.person'),
                    loading: true,
                    multiple: true,
                    search: FILTERS_SEARCHING.EMPLOYEES,
                    lazy: this.getEmployeesLazyLoading,
                    searchCallback: this.debouncedGetEmployees,
                    items: {
                        obj: 'getters',
                        module: 'employees',
                        prop: 'employees'
                    }
                })
            ];
        },
        ...mapState('filters', [
            'filters',
            'filtersSearching'
        ]),
        occStartDate () {
            return this.filters[FILTERS.OCC_START_DATE] || GET_BEGINNING_OF_CURRENT_MONTH();
        },
        occEndDate () {
            return this.filters[FILTERS.OCC_END_DATE] || PARSE_DATE(new Date());
        },
        utcOccStartDate () {
            return CONVERT_TO_UTC(`${this.occStartDate} 00:00:00`);
        },
        utcOccEndDate () {
            return CONVERT_TO_UTC(`${this.occEndDate} 23:59:59`);
        }
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchPositions();
            },
            deep: true
        },
        systemLogsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    debounce(this.fetchSystemLogs(), 1000);
                }
            }
        }
    },
    methods: {
        fetchSystemLogs () {
            this.loading = true;

            const params = {
                ...this.generateFilters()
            };
            if (this.search) {
                params['filter[search][match]'] = this.search;
            }
            if (this.sortingColumn) {
                params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            }
            this.$store.dispatch('systemSettings/getSystemLogs', params)
                .finally(() => {
                    this.loading = false;
                });
        },
        generateFilters () {
            const params = {
                locale: this.currentLocale,
                'page[number]': this.pagination.page,
                'page[size]': this.pagination.amount,
                'stats[total]': 'count',
                sort: '-occurrence_date'
            };

            this.filtersConfig.forEach(filter => {
                const f = filter.model;

                if (this.filters[f] && this.filters[f].length > 0) {
                    if (f === FILTERS.OCC_START_DATE) {
                        params[`filter[${f.slice(0, -1)}][gte]`] = CONVERT_TO_UTC(`${this.filters[f]} 00:00:00`);
                    } else if (f === FILTERS.OCC_END_DATE) {
                        params[`filter[${FILTERS.OCC_END_DATE.slice(0, -1)}][lte]`] = CONVERT_TO_UTC(`${this.filters[f]} 23:59:59`);
                    } else {
                        params[`filter[${f}]`] = this.filters[f].join(',');
                    }
                }
            });

            return params;
        },
        setDatesFilters () {
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.OCC_START_DATE, this.occStartDate);
            this.$refs.filtersBar.setSpecifyFilter(FILTERS.OCC_END_DATE, this.occEndDate);
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchSystemLogs();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchSystemLogs();
        }
    },
    mounted () {
        this.setFiltersWatcher(this.fetchSystemLogs);
        this.setDatesFilters();
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SYSTEM_SETTINGS)) {
                vm.fetchSystemLogs();
                vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                vm.$store.dispatch('employees/getEmployees')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.PERSON_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
                vm.$store.dispatch('systemLogTypes/getSystemLogTypes')
                    .then(() => {
                        vm.filtersConfig.find(el => el.model === FILTERS.SYSTEM_LOG_TYPE_ID).loading = false;
                        vm.$store.commit('filters/SET_CONFIG', vm.filtersConfig);
                    });
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.$store.dispatch('filters/clearFiltersParams');
        next();
    }
};
</script>

<style scoped>

</style>
