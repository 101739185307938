<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-data-table :headers="headers"
                      :loading="loader"
                      :items="systemLogs"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header, on }">
                <SortableHeader :header-item="header"
                                v-on="on"
                                :key="i"></SortableHeader>
            </template>
        </v-data-table>
        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import Pagination from '@/components/widgets/tables/Pagination';
import paginationSettings from '@/mixins/paginationSettings';

export default {
    name: 'SystemLogTable',
    components: { SortableHeader, Pagination },
    mixins: [paginationSettings],
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: (vm) => ({
        ICONS,
        pagination: vm.setupPagination(),
        sortable: {
            name: 'asc'
        }
    }),
    computed: {
        headers () {
            return [
                {
                    text: this.$t('inspections.summary.headers.lp'),
                    value: 'seq',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('labels.details'),
                    value: 'details',
                    sortable: true
                },
                {
                    text: this.$t('labels.code'),
                    value: 'code',
                    sortable: true
                },
                {
                    text: this.$t('labels.employee'),
                    value: 'employee_full_name',
                    sortable: true
                },
                {
                    text: this.$t('labels.description'),
                    value: 'code_description',
                    sortable: true
                },
                {
                    text: this.$t('labels.occurrence_date'),
                    value: 'converted_occurrence_date',
                    sortable: true
                }
            ];
        },
        ...mapState('systemSettings', [
            'systemLogs',
            'systemLogsAmount'
        ])
    },
    watch: {
        systemLogsAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        }
    }
};
</script>

<style scoped lang="scss">
    ::v-deep {
        & .v-data-table {
            & table {
                & .v-data-table-header__icon {
                    display: none;
                }
            }
        }
    }
</style>
